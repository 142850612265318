// "use strict";

// When user click on dropdwon, only 1 dopdown is open
function openDropdowns() {
  let ds = [...document.querySelectorAll("details")];
  ds.forEach((d) =>
    d.addEventListener("click", (e) => {
      e.shiftKey || ds.filter((i) => i != d).forEach((i) => i.removeAttribute("open"));
    })
  );
}

openDropdowns();

// Close modal fomr
function goBack() {
  window.history.back();
}

document.getElementById("button-bars").addEventListener("click", changeDiv);
function changeDiv(){
	var optionsMobile = document.getElementById("mobile-dropdown");
  optionsMobile.style.display = (optionsMobile.style.display == 'none') ? 'block' : 'none';
}

if (screen.width >= 950) {
  // Baground image move on mousemove
  function bgMove() {
    $(".bg-move").mousemove(function (e) {
      let moveX = (e.pageX * -0) / 35;
      let moveY = (e.pageY * -1) / 35;
      $(this).css("background-position", moveX + "px " + moveY + "px");
    });
  }
  bgMove();
}

$(document).ready(function(){
  var headerHeight = $(".header").height();
  $(".mt-script").css("margin-top", headerHeight + 50);
});

$(document).ready(function(){
  var DropMobile = $('.drop_mobile').click(function() {
    this.classList.toggle("active");
  });
});

$(document).ready(function(){
  let dropFlag = document.getElementById('drop-flag').addEventListener("click", function (){
    let optionsFlag = document.getElementById("navbar-dropdown_flag").classList.toggle("active");
  });
});

$(document).ready(function(){
  let dropFlag = document.getElementById('drop-flag_mobile').addEventListener("click", function (){
    let optionsFlag = document.getElementById("navbar-dropdown_flag-mobile").classList.toggle("active");
  });

  $('.quest-content_dropdown .dropdown-details').click(function(){
    if(window.innerWidth < 950){
      var swipeEle = $(this).find('.scroll-info');
      $(this).toggleClass('open');
      if(swipeEle.length && $(this).hasClass('open')){
          setTimeout(function(){
            swipeEle.fadeOut();
          }, 3000)
      }
      if(!$(this).hasClass('open')){
        swipeEle.show();
      }
    }
  })

  // default open how work option
  if($('#how-work').length){
    var itemDefault = window.location.hash.substr(1);
    $('.how-content_dropdown details:nth-child(' + itemDefault +')').attr('open', true)

  }

  // just allow letters
  $('.onlyText').keydown(function (e) {
    if (e.shiftKey || e.ctrlKey || e.altKey) {
      e.preventDefault();
    } else {
      var key = e.keyCode;
      if (!((key == 8) || (key == 32) || (key == 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
        e.preventDefault();
      }
    }
  });



  // list cities
  var cities = [];
  var html = "";
  $.getJSON('/build/js/colombia.json', function(data) {
    for(var i = 0; i < data.length; i++){
      for(var j = 0; j < data[i].ciudades.length; j++){
        cities.push(data[i].ciudades[j])
      }
    }
    for(var a = 0; a <= cities.length; a++) {
      html += `<option value="${cities[a]}">${cities[a]}</option>`;
      // "<option value=" + cities[a] + ">" + cities[a] + "</option>"
      document.getElementById("cities-list").innerHTML = html;
    }

  });

  // console.log(cities)
});
